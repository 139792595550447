
const digest = 'b64d56c4a275a43849089d59b6c52dc2d21def858e198a0fb73cc0450198924f';
const css = `._inc_kgzt3_1 {
  position: relative;
  z-index: 1;

  align-items: center;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  height: 3.5rem;
  justify-items: center;
  text-align: center;
  /* width: 3.5rem; */
}

._medium_kgzt3_15 ._allocation_kgzt3_15 {
  height: 2.5rem;
  width: 2.5rem;
}

._large_kgzt3_20 ._allocation_kgzt3_15 {
  height: 3rem;
  width: 3rem;
}

._amount_kgzt3_25 {
  position: absolute;
  z-index: 1;

  align-items: center;
  color: var(--color-neutral-10);
  display: flex;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  height: 100%;
  justify-content: center;
  text-align: center;
  width: 100%;
}

._large_kgzt3_20 ._amount_kgzt3_25 {
  font-size: 1.125rem;
}

._total_kgzt3_45 {
  stroke: var(--color-legacy-gray-3);
}

._total_kgzt3_45,
._progress_kgzt3_50,
._increment_kgzt3_51 {
  grid-column: 1 / span 1;
  grid-row: 0 / span 1;
}

._progress_kgzt3_50 {
  stroke: var(--project-color);
  /* axis compensation */
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}

._overage_kgzt3_63 ._progress_kgzt3_50 {
  stroke: var(--color-red);
}

._increment_kgzt3_51 {
  position: absolute;
  z-index: 2;

  align-items: center;
  background: var(--color-white);
  display: flex;
  height: 1.5rem;
  justify-content: center;
  /* transform: translateZ(0); */
  width: 100%;
}

._large_kgzt3_20 ._increment_kgzt3_51 {
  height: 1.75rem;
}

._fill_kgzt3_84 ._amount_kgzt3_25 {
  color: var(--color-neutral-40);
}

._fill_kgzt3_84 ._total_kgzt3_45 {
  fill: var(--project-color);
  fill-opacity: 0.2;
  stroke: var(--project-color);
  stroke-opacity: 0.4;
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"inc":"_inc_kgzt3_1","medium":"_medium_kgzt3_15","allocation":"_allocation_kgzt3_15","large":"_large_kgzt3_20","amount":"_amount_kgzt3_25","total":"_total_kgzt3_45","progress":"_progress_kgzt3_50","increment":"_increment_kgzt3_51","overage":"_overage_kgzt3_63","fill":"_fill_kgzt3_84"};
export { css, digest };
  